<template>
  <div id="fak">
    <h2 class="paastander">Hvilket fakultet/avdeling tilhører du?</h2> <!-- Expression/Question -->
    <button v-if="btn1 === false" v-on:click="OnClick(1)">Humaniora og Pedagogikk</button> <!-- Option 1 -->
    <button v-if="btn1 === true" class="optionsAlt" v-on:click="OnClick(1)">Humaniora og Pedagogikk</button> <!-- Option 1 -->

    <button v-if="btn2 === false" v-on:click="OnClick(2)">Teknologi og Realfag</button> <!-- Option 2 -->
    <button v-if="btn2 === true" class="optionsAlt" v-on:click="OnClick(2)">Teknologi og Realfag</button> <!-- Option 2 -->

    <button v-if="btn3 === false" v-on:click="OnClick(3)">Handelshøyskolen</button> <!-- Option 3 -->
    <button v-if="btn3 === true" class="optionsAlt" v-on:click="OnClick(3)">Handelshøyskolen</button> <!-- Option 3 -->

    <button v-if="btn4 === false" v-on:click="OnClick(4)">Lærerutdanningen</button> <!-- Option 4 -->
    <button v-if="btn4 === true"  class="optionsAlt" v-on:click="OnClick(4)">Lærerutdanningen</button> <!-- Option 4 -->

    <button v-if="btn5 === false" v-on:click="OnClick(5)">Kunstfag</button> <!-- Option 5 -->
    <button v-if="btn5 === true" class="optionsAlt" v-on:click="OnClick(5)">Kunstfag</button> <!-- Option 5 -->

    <button v-if="btn6 === false" v-on:click="OnClick(6)">Samfunnnsvitenskap</button> <!-- Option 6 -->
    <button v-if="btn6 === true" class="optionsAlt" v-on:click="OnClick(6)">Samfunnnsvitenskap</button> <!-- Option 6 -->

    <button v-if="btn7 === false" v-on:click="OnClick(7)">Helse og Idrett</button> <!-- Option 7 -->
    <button v-if="btn7 === true" class="optionsAlt" v-on:click="OnClick(7)">Helse og Idrett</button> <!-- Option 7 -->

  </div>
</template>

<script>
import {questions} from "@/sharedVars";

export default {
  name: "SpmHome",
  data(){
    return{
      btn1: false,
      btn2: false,
      btn3: false,
      btn4: false,
      btn5: false,
      btn6: false,
      btn7: false
    }
  },
  methods:{
    OnClick(option) { //Method called when button is clicked

      questions.set(0,option); //We need to set answer for faculty.
      //console.log("Clicked: " + option) //For testing
      switch (option){
        case 1:
          this.btn1 = true;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = false;
          this.btn7 = false;
          break;
        case 2:
          this.btn1 = false;
          this.btn2 = true;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = false;
          this.btn7 = false;
          break;
        case 3:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = true;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = false;
          this.btn7 = false;
          break;
        case 4:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = true;
          this.btn5 = false;
          this.btn6 = false;
          this.btn7 = false;
          break;
        case 5:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = true;
          this.btn6 = false;
          this.btn7 = false;
          break;
        case 6:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = true;
          this.btn7 = false;
          break;
        case 7:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = false;
          this.btn7 = true;
          break;
      }
    },
  }
}
</script>

<style scoped>

</style>